import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Filters } from 'elements/Filter';
import { PaginationHandler } from 'elements/pagination-handler';
import { PubSub } from 'lib/event/PubSub';
import { DataFilter, QueryFilter } from 'lib/tables/DataFilter';

export type FilterManagerInit<T = Filters> = {
  context: string;
  storageKey: string;
  filters: T;
  defaultFilters: Record<keyof T, boolean>;
};

type SelectedFilter = { [key: string]: string };

@autoinject
export class FilterManager {
  private state: FilterManagerInit<unknown>;

  private dataFilter: DataFilter = new DataFilter();

  get storageKey() {
    return this.state.storageKey;
  }

  get filters() {
    return this.state.filters;
  }

  get context() {
    return this.state.context;
  }

  set filters(filters) {
    this.state.filters = filters;
  }

  set selectedFilters(filter: SelectedFilter) {
    this.dataFilter.filters = filter;
  }

  get defaultFilters() {
    return this.state.defaultFilters;
  }

  set defaultFilters(f) {
    this.state.defaultFilters = f;
  }

  set currentPage(page: number) {
    this.pagination.currentPage = page;
  }

  get currentPage() {
    return this.pagination.currentPage;
  }

  set paginationTotalRecords(items: number) {
    this.pagination.totalItems = items;
  }

  get paginationTotalRecords() {
    return this.pagination.totalItems;
  }

  get queryFilter() {
    const clone = structuredClone(this.dataFilter) as QueryFilter;
    clone.asQueryString = this.dataFilter.asQueryString;
    return clone;
  }

  constructor(
    private pubsub: PubSub,
    private pagination: PaginationHandler,
    private router: Router
  ) {
    this.pubsub.sub('filter-section:reset-filters', (data) => {
      if (data.context !== this.state.context) return;
      // this.filterClearAll();
      // call callback to get new data
      this.notifyChange();
    });

    this.pubsub.sub('filter-section:list-settings-changed', (data) => {
      if (this.dataFilter.top === data.settings.pageSize) return;
      this.dataFilter.top = data.settings.pageSize;
      this.pagination.pageSize = data.settings.pageSize;
      this.pagination.currentPage = 1;
      this.notifyChange();
    });

    this.pubsub.sub('filter-section:search-text-changed', (data) => {
      if (data.context !== this.state.context) return;
      this.dataFilter.searchText = data.searchText;
      this.notifyChange();
    });

    this.pubsub.sub('pagination:page-change', (pagination) => {
      this.dataFilter.skip = pagination.skip;
      this.dataFilter.top = pagination.top;
    });

    this.pubsub.sub('filter:changed', (f) => {
      if (!f) return;

      this.notifyChange();
    });
  }

  init<T>(args: FilterManagerInit<T>) {
    this.state = args;
    this.pubsub.publish('filter-manager:init', args);
  }

  setOrderByColumn(column: string) {
    this.dataFilter.setOrderByAndFlipOrderDirectionIfSameKey(column);
    this.notifyChange();
  }

  protected notifyChange() {
    const clone = structuredClone(this.dataFilter) as QueryFilter;
    clone.asQueryString = this.dataFilter.asQueryString;
    this.pubsub.publish('filter-manager:filter-changed', clone);
  }

  protected setupPagination() {
    const routeParams = this.router.currentInstruction?.queryParams;
    let currentPage = 1;
    if (routeParams && routeParams.currentPage) {
      currentPage = +routeParams.currentPage;
    }

    this.pagination.init({
      currentPage: currentPage,
      totalItems: 0,
    });
  }

  protected detached() {
    this.pubsub.unsub();
  }

  unbind() {
    this.pubsub.unsub();
  }
}
